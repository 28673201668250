// @flow
import * as React from 'react'

import withTheme from 'hoc/withTheme'
import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

function Header (props) {
    const { theme } = props
    return (
        <>
            <div className={styles.bgWrapper} style={{
                '--bg-desktop': `url(${theme.background})`,
                '--bg-mob': `url(${theme.backgroundMobile})`
            }}>
                <div className={styles.cars}>
                    <div alt={'cars'} style={{
                        '--cars': `url(${theme.cars})`,
                        '--cars-mobile': `url(${theme.carsMobile})`
                    }} />
                </div>
            </div>
        </>
    )
}

export default withTheme(supportedThemes)(Header)
