// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import MessageIncentive from 'components/MessageIncentive'
import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import ValuesSummary from 'components/ValuesSummary'
import LeadGenForm from 'components/LeadGenForm/Step1/Step1'
import styles from './styles.module.scss'

import { Store } from 'Store'
import { updateLead } from 'Actions'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-1', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-1', 'step-2')}${props.location.search}`} push />
    }
    return (
        <>
            <Header/>
            <div className={styles.step}>
                <div className={styles.container}>
                    <Sidebar className={styles.sidebarContainer}>
                        <MessageIncentive />
                    </Sidebar>
                    <div className={styles.formContainer}>
                        <LeadGenForm
                            onSubmit={async (data, done) => {
                                try {
                                    let lead = {
                                        fields: data,
                                        isComplete: false
                                    }
                                    await updateLead(dispatch, state.code, lead)
                                    setSubmitted(true)
                                } catch (err) {
                                    alert('Oops something went wrong')
                                    console.error(err)
                                    done(err)
                                }
                            }}
                            initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                        />
                    </div>
                </div>
            </div>
            <ValuesSummary />
        </>
    )
}
