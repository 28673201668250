
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsf8d032b95ff6803ead4921bc5d7fbde2en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'f8d032b95ff6803ead4921bc5d7fbde2', flattenObject(nsf8d032b95ff6803ead4921bc5d7fbde2en_US), true, true);
        
            import nsf8d032b95ff6803ead4921bc5d7fbde2en from './en.yaml'
            i18n.addResourceBundle('en', 'f8d032b95ff6803ead4921bc5d7fbde2', flattenObject(nsf8d032b95ff6803ead4921bc5d7fbde2en), true, true);
        
        export default 'f8d032b95ff6803ead4921bc5d7fbde2';
    