// @flow
import * as React from 'react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'

import { Store } from 'Store'
import LandingMask from 'components/LandingMask'
import Hero from 'components/Hero'
import HeroSlider from 'components/HeroSlider'
import TopBar from 'components/TopBar'
import ValuesSummary from 'components/ValuesSummary'

import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default withRouter((props: Props) => {
    const { dispatch } = React.useContext(Store)

    React.useMemo(() => props.history.listen(() => {
        if (window.location.pathname.split('/').length === 2) {
            dispatch({
                type: 'SET_LEAD',
                payload: null
            })
        }
    }), [props.history, dispatch])

    const { state } = React.useContext(Store)

    return (
        <>
            <TopBar locale={state.locale} date={state.campaign.expiryDate}/>
            <LandingMask className={styles.container}>
                <div className={styles.innerPadding}>
                    <div className={styles.leftSide}>
                        <HeroSlider />
                    </div>
                    <div className={cn(styles.registerForm, styles.rightSide)}>
                        <Hero/>
                    </div>
                </div>
            </LandingMask>
            <div className={styles.valuesSummary}>
                <ValuesSummary />
            </div>
        </>
    )
})
