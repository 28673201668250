// @flow
import * as React from 'react'
import { Store } from 'Store.js'
import cn from 'classnames'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes'

import VoucherValue from 'components/VoucherValue'
import CashbackAndRates from 'components/CashbackAndRates'

const ValuesSummary = ({ theme }) => {
    const { state } = React.useContext(Store)
    const incentives = state.campaign.incentives.manufacturer
    const hiddenCashBack = state.campaign.incentives.manufacturer.cashBack === 0
    return (
        <div className={cn(styles.container, {
            [styles.areCombinable]: incentives.areCombinable,
            [styles.hiddenCashBack]: hiddenCashBack
        })} style={{ '--label-background': theme.labelBackground }}>
            <div className={styles.summaryFooter}>
                <div className={styles.voucherContainer}>
                    <VoucherValue />
                </div>
                <div></div>
                <div className={styles.cashbackAndRatesContainer}>
                    <CashbackAndRates className={styles.cashbackAndRates} />
                </div>
            </div>
        </div>
    )
}

export default withTheme(supportedThemes)(ValuesSummary)
