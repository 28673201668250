
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns8401bc74a3f2e257e9eaa92dbb28d7feen_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '8401bc74a3f2e257e9eaa92dbb28d7fe', flattenObject(ns8401bc74a3f2e257e9eaa92dbb28d7feen_US), true, true);
        
            import ns8401bc74a3f2e257e9eaa92dbb28d7feen from './en.yaml'
            i18n.addResourceBundle('en', '8401bc74a3f2e257e9eaa92dbb28d7fe', flattenObject(ns8401bc74a3f2e257e9eaa92dbb28d7feen), true, true);
        
        export default '8401bc74a3f2e257e9eaa92dbb28d7fe';
    