function getVehicleImageUrl (
    make: string,
    name: string,
    year: ?number,
    color: string = 'gray',
    angle: string = 'front_34',
    size: string = 'l'
) {
    return encodeURI(`${process.env.REACT_APP_VEHICLE_IMAGES_BASE_URL}${make}.${name}.${year || 'latest'}.${angle}.${color}.${size}.png`)
}

export default getVehicleImageUrl
