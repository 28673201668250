// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import { Store } from 'Store'
import { updateLead } from 'Actions'

import Header from 'components/Header'
import ValuesSummary from 'components/ValuesSummary'
import LeadGenForm from 'components/LeadGenForm/Step2'

// import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)

    const handleSelect = React.useCallback(async (make, model, year, name, color) => {
        const lead = {
            ...state.lead,
            fields: {
                ...state.lead.fields,
                newVehicleMake: make,
                newVehicleModel: model,
                newVehicleModelYear: year,
                newVehicleName: name,
                newVehicleModelColor: color
            }
        }
        await updateLead(dispatch, state.code, lead)
        setSubmitted(true)
    }, [state.lead, state.code, dispatch])

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-2', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-2', 'step-3')}${props.location.search}`} push />
    }

    return (
        <>
            <Header />
            <div>
                <div>
                    <LeadGenForm
                        onSelect={handleSelect}
                        setSubmitted={setSubmitted}
                        initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                    />
                </div>
            </div>
            <ValuesSummary />
        </>
    )
}
