// @flow
import * as React from 'react'

import { Currency } from 'i18n/NumberFormatter'
import { useTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'
import AbsoluteTime from 'components/AbsoluteTime'
import { useMediaQuery } from 'beautiful-react-hooks'
import transDomain from './translations/index.translations'
import { Store } from 'Store'
import getVehicleImageUrl from 'util/getVehicleImageUrl'
import { wrapText } from '../Form/Helpers'

type Props = {
    width: string, // eg. "541px"
    locale: string,
    lead: { [string]: mixed },
    campaign: { [string]: mixed },
    hiddenButtons?: Boolean,
}

const Voucher = React.forwardRef((props: Props, ref: React.Ref) => {
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const { lead, campaign, locale, currency } = state
    const currencyFormatter = Currency(currency, locale)
    const isMobile = useMediaQuery('(max-width: 767.98px)')

    const voucher = lead.incentives.voucher

    const primaryColor = props.theme.primaryColor
    const secondaryColor = props.theme.secondaryColor
    const ternaryColor = props.theme.ternaryColor
    const website = props.hiddenButtons ? null : (lead.promoMaterial?.website || null)
    const titleColor = props.theme.titleColor
    const textColor = props.theme.textColor
    const messageColor = props.theme.messageColor

    const carUrl = getVehicleImageUrl(
        state.lead.fields.newVehicleMake,
        state.lead.fields.newVehicleName,
        state.lead.fields.newVehicleModelYear,
        state.lead.fields.newVehicleModelColor,
        'front_34',
        'm'
    )

    const terms = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        100
    )

    const termsTablet = wrapText(
        campaign.incentives.voucher.terms.replace('[dealership_name]', campaign.client.name),
        67
    )

    if (isMobile) {
        return (
            <svg
                width="100%"
                height="833"
                ref={ref}
                viewBox="0 0 414 833"
            >
                <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                    <g transform="translate(0 -145)">
                        <g transform="translate(10 145)">
                            <rect
                                width="391"
                                height="833"
                                x="1.5"
                                y="1.5"
                                fill="#FFF"
                                stroke={secondaryColor}
                                strokeWidth="3"
                                rx="5"
                            ></rect>
                            <path fill={secondaryColor} d="M1 255H394V319H1z"></path>
                            <text
                                fill={primaryColor}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="10"
                                fontWeight="normal"
                                letterSpacing="-0.1"
                            >
                                <tspan x="200" y="280" textAnchor="middle">
                                    This {voucher.name} is redeemable only at
                                </tspan>
                                <tspan x="200" y="295" textAnchor="middle">
                                    <tspan fontWeight="bolder">{campaign.client.name}</tspan>
                                    &nbsp;Valid Until: <tspan fontWeight="bolder"><AbsoluteTime noContainer dateFormat='long' date={campaign.expiryDate}/>
                                    </tspan>
                                </tspan>
                            </text>
                            <text
                                fill={textColor}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="8.9"
                                fontWeight="normal"
                                textAnchor="middle"
                                transform="translate(-10 321)"
                                letterSpacing="0"
                            >
                                {termsTablet.map((line, i) => (
                                    <tspan x="50%" y={14 * (i + 1)} key={i}>{line}</tspan>
                                ))}
                            </text>
                            <text
                                fill={ternaryColor}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="90"
                                fontWeight="normal"
                                letterSpacing="-0.741"
                            >
                                <tspan x="200" y="140" textAnchor="middle">
                                    {currencyFormatter.format(voucher.value)}
                                </tspan>
                            </text>
                            <text
                                fill={primaryColor}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="16"
                                fontWeight="normal"
                            >
                                <tspan x="200" y="48" textAnchor="middle">
                                    {t('voucher_mobile.prefix', {
                                        name: lead.fields.firstName
                                    })}
                                </tspan>
                            </text>
                            <text
                                fill={primaryColor}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="16"
                                fontWeight="normal"
                            >
                                <tspan x="200" y="179" textAnchor="middle">
                                    {t('voucher_mobile.name.1', { name: voucher.name })}
                                </tspan>
                                <tspan x="200" y="207" textAnchor="middle">
                                    {t('voucher_mobile.name.2', {
                                        vehicle: `${state.lead.fields.newVehicleModelYear} ${state.lead.fields.newVehicleMake} ${state.lead.fields.newVehicleModel}`
                                    })}
                                </tspan>
                            </text>
                        </g>
                        <path
                            fill={secondaryColor}
                            d="M10 573h394v401a5 5 0 01-5 5H15a5 5 0 01-5-5V573z"
                        ></path>
                        {website && (
                            <text
                                fill={messageColor}
                                fontFamily="'Oxygen', 'sans-serif'"
                                fontSize="15"
                                fontWeight="normal"
                            >
                                <tspan x="209" y="841" textAnchor="middle">
                                    {t('voucher_mobile.selection.1')}
                                </tspan>
                                <tspan x="209" y="866" textAnchor="middle">
                                    {t('voucher_mobile.selection.2')}
                                </tspan>
                            </text>
                        )}
                        <g
                            fill={titleColor}
                            fontFamily="'Yanone Kaffeesatz', sans-serif"
                            fontSize="40"
                            fontWeight="bolder"
                            transform="translate(0 769)"
                        >
                            <text>
                                <tspan x="209" y="25.137" textAnchor="middle" fontSize="30px">
                                    {`${state.lead.fields.newVehicleModelYear} ${state.lead.fields.newVehicleMake} ${state.lead.fields.newVehicleModel}`}
                                </tspan>
                            </text>
                        </g>
                        {website && (
                            <g transform="translate(43 899)">
                                <rect
                                    width="329"
                                    height="50"
                                    x="0"
                                    y="0"
                                    fill={ternaryColor}
                                    rx="3"
                                ></rect>
                                <text
                                    fill="#FFF"
                                    fontSize="22"
                                    fontWeight="normal"
                                    letterSpacing="1.222"
                                >
                                    <tspan x="165" y="30" textAnchor="middle">
                                        <a href={website} fill={'#FFFFFF'} target="_blank" rel="noopener noreferrer">
                                            {t('voucher_mobile.selection.button')}
                                        </a>
                                    </tspan>
                                </text>
                            </g>
                        )}
                    </g>
                    <image
                        x="65"
                        y="415"
                        width="304"
                        height="246"
                        xlinkHref={carUrl}
                    />
                </g>
            </svg>
        )
    }

    return (
        <svg ref={ref} width={props.width} viewBox="0 0 559 217" version="1.1">
            <g data-name="Layer 2">
                <g data-name="Layer 1">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <rect
                                            width="559"
                                            height="213.33"
                                            x="1.5"
                                            y="1.5"
                                            fill="#fff"
                                            stroke={secondaryColor}
                                            strokeWidth="3"
                                            rx="2.35"
                                        ></rect>
                                        <path
                                            fill={secondaryColor}
                                            fillRule="evenodd"
                                            d="M351.17.79h207.68a2.36 2.36 0 012.35 2.36v210a2.36 2.36 0 01-2.35 2.36H351.17z"
                                        ></path>
                                    </g>
                                    {website && (
                                        <>
                                            <g>
                                                <rect
                                                    width="154.94"
                                                    height="23.55"
                                                    x="378.95"
                                                    y="161.85"
                                                    fill={ternaryColor}
                                                    rx="1.41"
                                                ></rect>
                                                <text
                                                    transform="translate(430 177)"
                                                    fill="#fff"
                                                    fontFamily="'Oxygen', 'sans-serif'"
                                                    fontSize="10.361"
                                                    letterSpacing=".055em"
                                                >
                                                    <tspan>
                                                        <a href={website} fill={'#FFFFFF'} target="_blank" rel="noopener noreferrer">
                                                            {t('selection.button')}
                                                        </a>
                                                    </tspan>
                                                </text>
                                            </g>

                                            <g
                                                fill={messageColor}
                                                fontFamily="'Oxygen', 'sans-serif'"
                                                fontSize="7.064"
                                            >
                                                <text
                                                    transform="translate(394.34 139.25)"
                                                    letterSpacing="-0.25px"
                                                    fontWeight="209"
                                                >
                                                    <tspan x="60" y="0" textAnchor="middle">
                                                        {t('selection.1')}
                                                    </tspan>
                                                    <tspan x="60" y="12" textAnchor="middle">
                                                        {t('selection.2')}
                                                    </tspan>
                                                </text>
                                            </g>
                                        </>
                                    )}
                                </g>
                                <g>
                                    <text
                                        fill={ternaryColor}
                                        data-name="$XXXX"
                                        fontFamily="'Oxygen', 'sans-serif'"
                                        fontSize="61.4321"
                                        letterSpacing="1.25px"
                                    >
                                        <tspan textAnchor="middle" x="177.25" y="92.5" fontSize="60" fontWeight="bold">
                                            {currencyFormatter.format(voucher.value)}
                                        </tspan>
                                    </text>
                                    <text
                                        fill={primaryColor}
                                        fontFamily="'Oxygen', 'sans-serif'"
                                        fontSize="9.419"
                                        fontWeight="400"
                                    >
                                        <tspan textAnchor="middle" x="177.25" y="35" letterSpacing="-0.24px" fontSize="9.32">
                                            {t('voucher.prefix', { name: lead.fields.firstName })}
                                        </tspan>
                                    </text>
                                    <g
                                        fill={primaryColor}
                                        fontFamily="'Oxygen', 'sans-serif'"
                                        fontSize="9.32"
                                        letterSpacing="-0.24px"
                                    >
                                        <text
                                        >
                                            <tspan textAnchor="middle" x="177.25" y="117">
                                                {t('voucher.name', { name: voucher.name })}
                                            </tspan>
                                        </text>
                                        <text
                                        >
                                            <tspan textAnchor="middle" x="177.25" y="132">
                                                {`${state.lead.fields.newVehicleModelYear} ${state.lead.fields.newVehicleModel}`}
                                            </tspan>
                                        </text>
                                    </g>
                                </g>
                                <g
                                    fill={primaryColor}
                                    fontFamily="'Oxygen', 'sans-serif'"
                                    fontSize="4.5"
                                    textAnchor='middle'
                                    transform="translate(-105 150)"
                                >
                                    <text textAnchor='middle' letterSpacing='0'>
                                        {terms.map((line, i) => (
                                            <tspan key={i} x="50%" y={(terms.length > 2 ? 32 : 34) + i * (terms.length > 2 ? 7 : 8)}>
                                                {line}
                                            </tspan>
                                        ))}
                                    </text>
                                </g>
                                <g>
                                    <text
                                        fill={titleColor}
                                        data-name="2020-Escape"
                                        fontFamily="'Yanone Kaffeesatz', sans-serif"
                                        fontSize="19"
                                        fontWeight="bolder"
                                    >
                                        {wrapText(`${state.lead.fields.newVehicleModelYear || ''} ${state.lead.fields.newVehicleModel}`).map((line, index) => (
                                            <tspan x="455" y={website ? '112.5' : '153'} letterSpacing="0" textAnchor="middle" dy={index * 16}>
                                                {line}
                                            </tspan>
                                        ))}
                                    </text>
                                </g>
                                <image
                                    x="315"
                                    y={website ? '-20' : '20'}
                                    width="299"
                                    height="143"
                                    xlinkHref={carUrl}
                                />
                                <path
                                    fill={secondaryColor}
                                    d="M1.74 155.73H351.17V174.57H1.74z"
                                ></path>
                                <text
                                    fill={primaryColor}
                                    fontFamily="'Oxygen', 'sans-serif'"
                                    fontSize="4.709"
                                    letterSpacing="-.01em"
                                    fontWeight="300"
                                >
                                    <tspan x="172.5" y="166.5" letterSpacing="-.034em" fontSize="5.35" textAnchor="middle">
                                        This {voucher.name} is redeemable only at <tspan
                                            fontWeight="bolder">{campaign.client.name}</tspan>
                                        &nbsp;Valid Until: <tspan fontWeight="bolder">
                                            <AbsoluteTime
                                                noContainer
                                                dateFormat='long'
                                                date={campaign.expiryDate}
                                        />
                                        </tspan>
                                    </tspan>
                                </text>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
})

Voucher.defaultProps = {
    width: 90
}

export default withTheme(supportedThemes)(Voucher)
