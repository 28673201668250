// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import { Store } from 'Store'
import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes.js'

import styles from './styles.module.scss'

import transDomain from './translations/index.translations'

import messageSignature from './images/signature.png'

function MessageIncentive (props) {
    const { state } = React.useContext(Store)
    const { t } = props

    return (
        <div className={styles.message}>
            <h2 style={{
                color: props.theme.messagetTitle
            }}>{t('message_title', {
                position: state.campaign.contactInfo.position
            })}</h2>

            <p className={styles.messageParagraph}>{t('message_paragraph')}</p>

            <img className={styles.messageSignature} src={messageSignature} alt="" />

            <div className={styles.messageFooter}>
                <strong>{state.campaign.contactInfo.name}</strong>
                <span>{state.campaign.contactInfo.position}, {state.campaign.client.name}</span>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)((MessageIncentive)))
