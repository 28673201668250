// @flow
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import { Store } from 'Store'
import { updateLead } from 'Actions'

import Header from 'components/Header'
import ValuesSummary from 'components/ValuesSummary'
import Sidebar from 'components/Sidebar'
import LeadGenForm from 'components/LeadGenForm/Step3'
import ShoppingCard from 'components/ShoppingCard'

import styles from './styles.module.scss'

type Props = {
    location: Location
}

export default (props: Props) => {
    const { state, dispatch } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    const [toSelection, setToSelection] = React.useState(false)

    const handleOnSelection = React.useCallback(() => {
        setToSelection(true)
    }, [])

    if (!state.lead) {
        return <Redirect to={`${props.location.pathname.replace('/step-3', '')}${props.location.search}`} push />
    }

    if (submitted) {
        return <Redirect to={`${props.location.pathname.replace('step-3', 'success')}${props.location.search}`} push />
    }

    if (toSelection) {
        return <Redirect to={`${props.location.pathname.replace('step-3', 'step-2')}${props.location.search}`} push />
    }

    const model = state.campaign.models.find(m => m.make === state.lead.fields.newVehicleMake && m.name === state.lead.fields.newVehicleModel && m.year === state.lead.fields.newVehicleModelYear)
    const color = state.lead.fields.newVehicleModelColor

    return (
        <>
            <Header />
            <div className={styles.step}>
                <div className={styles.container}>
                    <Sidebar className={styles.sidebarContainer}>
                        <ShoppingCard
                            isVisible={true}
                            buttonAction={'text'}
                            buttonText={'Change Your Choice'}
                            onClick={handleOnSelection}
                            xl
                            colorProp={color}
                            make={model.make}
                            name={model.name}
                            year={model.year}
                            voucherValue={state.campaign.incentives.voucher.type === 'variable' ? model.voucherValue : state.campaign.incentives.voucher.value}
                            startingPrice={model.startingPrice}
                        />
                    </Sidebar>
                    <div className={styles.formContainer}>
                        <LeadGenForm
                            onSubmit={async (data, done) => {
                                try {
                                    let lead = {
                                        fields: data,
                                        isComplete: true
                                    }
                                    await updateLead(dispatch, state.code, lead)
                                    setSubmitted(true)
                                } catch (err) {
                                    alert('Oops something went wrong')
                                    console.error(err)
                                    done(err)
                                }
                            }}
                            initialValues={state.lead && state.lead.fields ? state.lead.fields : {}}
                    />
                    </div>
                </div>
            </div>
            <ValuesSummary />
        </>
    )
}
