// @flow
import * as React from 'react'

import SuccessInfoArea from 'components/SuccessInfoArea'

import withTheme from 'hoc/withTheme'
import supportedThemes from './themes/__supportedThemes'

import HeaderWithVoucher from 'components/HeaderWithVoucher'

import styles from './styles.module.scss'

function SuccessInfo (props) {
    return (
        <div className={styles.container}>
            <HeaderWithVoucher />
            <SuccessInfoArea />
        </div>
    )
}

export default withTheme(supportedThemes)(SuccessInfo)
